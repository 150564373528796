import { useMediaQuery, useWindowDimensions } from 'hooks';
import { type FC } from 'react';

import { Formatter } from 'utils';

interface PdfDocumentType {
  base64: string;
}

export const PdfDocument: FC<PdfDocumentType> = ({ base64 }) => {
  const { desktop } = useMediaQuery();
  const { height, width } = useWindowDimensions();

  return (
    <iframe
      height={desktop ? height - 96 : height - 42}
      width={desktop ? getDesktopWidth(width) : width}
      src={Formatter.base64PDFToBlobUrl(base64)}
      title="media"
      style={{ border: 'none' }}
    />
  );
};

function getDesktopWidth(_width: number) {
  const width = _width - 96;
  return width > 900 ? 900 : width;
}
