import { type FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { Box, Button, IconButton } from '@mui/material';

import { type SharedStorageList, type StorageFile } from 'types/sharedStorage';
import { useMediaQuery, useSharedStorageFile } from 'hooks';
import { Formatter } from 'utils';
import { Item, PrintComponent } from './fragments';

import { ReactComponent as PrintIcon } from 'assets/icons/print.svg';
import { sx } from './styles';

interface GeneralInfoType {
  id: SharedStorageList['id'];
  data: StorageFile;
  onClick: () => void;
}

export const GeneralInfo: FC<GeneralInfoType> = ({
  id,
  data: { name, id: fileId, category, date, description, type },
  onClick,
}) => {
  const { desktop } = useMediaQuery();
  const { t } = useTranslation();
  const componentRef = useRef<HTMLDivElement>(null);

  const { data: media, isSuccess } = useSharedStorageFile({ id, fileId });

  const onImagePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const href =
    media?.data && media.contentType
      ? Formatter.srcFromBase64({
          base64: media.data,
          prefix: media.contentType,
        })
      : '';

  return (
    <Box sx={sx.root}>
      <Item title={t('DOCUMENT_NAME')} text={name} />
      <Item title={t('CATEGORY')} text={t(category || '')} />
      <Item
        title={t('CREATION_DATE')}
        text={Formatter.dateFormat({ date, from: 'Base', to: 'DateSlashed' })}
      />
      <Item title={t('DESCRIPTION')} text={description} />
      <Box sx={sx.buttonsWrapper}>
        {desktop && (
          <IconButton
            onClick={type === 'PDF' ? onClick : onImagePrint}
            sx={sx.printButton}
            disabled={!isSuccess}
          >
            <PrintIcon />
          </IconButton>
        )}
        <Button
          sx={sx.downloadButton}
          href={href}
          download={name}
          disabled={!href}
        >
          {t('DOWNLOAD')}
        </Button>
        {desktop && (
          <Box sx={sx.printComponent}>
            {media && media.contentType !== 'application/pdf' && (
              <PrintComponent
                data={media.data}
                contentType={media.contentType}
                innerRef={componentRef}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
