import { type SxProps, type Theme } from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  root: {
    display: 'flex',
    overflow: 'hidden',
    flex: 1,
  },

  scrollbar: {
    padding: {
      lg: '0 24px',
    },
  },

  table: {
    position: {
      lg: 'relative',
    },
  },

  tableHead: (t) => ({
    position: {
      lg: 'sticky',
    },
    top: {
      lg: 0,
    },
    backgroundColor: {
      lg: t.palette.background.default,
    },
  }),

  tableHeaderCell: {
    position: {
      sm: 'relative',
    },
  },

  tableHeaderText: {
    typography: {
      xs: '12_16_700',
      sm: '16_20_800',
    },
  },

  tableBodyRow: (t) => ({
    backgroundColor: t.palette.background.default,
  }),

  tableBodyText: {
    typography: {
      xs: '12_16_600',
      sm: '14_18_600',
    },
    wordBreak: 'break-word',
  },
};
