import { type FC } from 'react';
import { Box } from '@mui/material';

import { useSharedStorageThumbnailFile } from 'hooks';
import { StorageThumbnailEnum } from 'types/sharedStorage';
import { Preloader } from 'components/Preloader';
import { Formatter } from 'utils';

import { sx, Image } from './styles';

export interface MediaProps {
  id: string;
  fileId: string;
  onClick: () => void;
}

export const Media: FC<MediaProps> = ({ id, fileId, onClick }) => {
  const { data: thumbnail, isFetching: isThumbnailFetching } =
    useSharedStorageThumbnailFile({
      id,
      fileId,
      type: StorageThumbnailEnum.LARGE,
    });

  const data = thumbnail?.[0]?.data;
  const base64 = data?.data;
  const prefix = data?.contentType;

  if (!base64) return null;

  return (
    <Box sx={sx.root}>
      {isThumbnailFetching ? (
        <Preloader />
      ) : (
        <Image
          onClick={onClick}
          src={Formatter.srcFromBase64({
            base64,
            prefix,
          })}
          alt=""
        />
      )}
    </Box>
  );
};
