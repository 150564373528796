import { useMediaQuery as useMediaQueryMui, useTheme } from '@mui/material';

export const useMediaQuery = () => {
  const theme = useTheme();

  const mobile = useMediaQueryMui(theme.breakpoints.down('sm'));
  const tablet = useMediaQueryMui(theme.breakpoints.between('sm', 'lg'));
  const desktop = useMediaQueryMui(theme.breakpoints.up('lg'));

  return {
    mobile,
    tablet,
    desktop,
  };
};
