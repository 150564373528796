import { API } from 'api/config';
import {
  type SharedStorageFileRequest,
  type SharedStorageFile,
  type SharedStorageListRequest,
  type SharedStorageList,
  type StorageFile,
  type SharedStorageThumbnailFile,
  type SharedStorageThumbnailFileRequest,
  type SharedStorageDetailsRequest,
  type SharedSrotageDetails,
  type SharedStorageDetailsResponse,
} from 'types/sharedStorage';

export async function getList({
  id,
}: SharedStorageListRequest): Promise<StorageFile[]> {
  const { data } = await API.post<SharedStorageList>(
    `${endpoints.sharedStorage}/${id}`
  );

  return data.files || [];
}

export async function getFile({
  id,
  fileId,
}: Partial<SharedStorageFileRequest>) {
  const { data } = await API.post<SharedStorageFile>(
    `${endpoints.sharedStorage}/${id}/${fileId}`
  );

  return data;
}

export async function getThumbnailFile({
  id,
  fileId,
  type,
}: Partial<SharedStorageThumbnailFileRequest>) {
  const { data } = await API.post<SharedStorageThumbnailFile>(
    `${endpoints.sharedStorage}/${id}/${fileId}/thumbnails?type=${type}`
  );

  return data;
}

export async function getDetails({
  id,
}: SharedStorageDetailsRequest): Promise<SharedSrotageDetails[]> {
  const { data } = await API.post<SharedStorageDetailsResponse>(
    `${endpoints.sharedStorage}/${id}/details`
  );

  return data.storageDetails || [];
}

const endpoints = {
  sharedStorage: '/shared-storage',
};
