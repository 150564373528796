import { type FC } from 'react';
import { Dialog, styled, DialogContent, Box } from '@mui/material';

import { PdfDocument } from 'components/PdfDocument';
import { useMediaQuery, useSharedStorageFile } from 'hooks';
import { Formatter, TypeGuardians } from 'utils';
import { type MediaProps } from '../Media';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { Preloader } from 'components/Preloader';

type MediaModalType = Omit<MediaProps, 'onClick'> & {
  onClose: () => void;
};

export const MediaModal: FC<MediaModalType> = ({ id, fileId, onClose }) => {
  const { desktop } = useMediaQuery();
  const {
    data: media,
    isFetching,
    isSuccess,
  } = useSharedStorageFile({ id, fileId });

  const isShowPdf = isSuccess && TypeGuardians.isPdf(media);
  const isShowImage = isSuccess && TypeGuardians.isImage(media);

  return (
    <Dialog open onClose={onClose} fullScreen={!desktop}>
      <DialogContent>
        <Box sx={{ position: 'absolute', top: 0, right: 0, padding: 9 }}>
          <Icon onClick={onClose} />
        </Box>
        {isFetching && (
          <Box
            sx={(t) => ({
              width: '100%',
              borderRadius: '4px',
              backgroundColor: t.palette.background.default,
            })}
          >
            <Preloader />
          </Box>
        )}
        {isShowPdf && <PdfDocument base64={media.data} />}
        {isShowImage && (
          <Image
            src={Formatter.srcFromBase64({
              base64: media.data,
              prefix: media.contentType,
            })}
            alt=""
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

const Icon = styled(CloseIcon)`
  ${(props) => props.theme.breakpoints.down('lg')} {
    path {
      stroke: ${(props) => props.theme.palette.text.primary};
    }
  }
`;

const Image = styled('img')`
  border-radius: 4px;
  max-width: 100%;
  align-self: flex-start;
  object-fit: contain;
  max-height: calc(100vh - 96px);
`;
