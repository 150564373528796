import { type FC, type PropsWithChildren } from 'react';
import { ThemeProvider } from 'theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import 'fonts/fonts.css';
import './i18n/i18n';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  },
});

export const ContextWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </ThemeProvider>
  );
};
