import { type AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { sharedStorageService } from 'services';
import {
  type SharedStorageDetailsRequest,
  type SharedSrotageDetails,
} from 'types/sharedStorage';
import { type ValidationError } from 'types/error';

export const useSharedStorageDetails = (
  params: SharedStorageDetailsRequest
) => {
  return useQuery<SharedSrotageDetails[], AxiosError<ValidationError>>({
    queryKey: ['sharedStorageDetails', params],
    queryFn: () => sharedStorageService.getDetails(params),
  });
};
