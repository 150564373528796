import { type FC, useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { type StorageFile } from 'types/sharedStorage';

import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';

interface DocumentPaginationType {
  files: StorageFile[];
  selectedFileId: StorageFile['id'];
  onSelect: (file: StorageFile) => void;
}

export const DocumentPagination: FC<DocumentPaginationType> = ({
  files,
  selectedFileId,
  onSelect,
}) => {
  const { t } = useTranslation();

  const [selectedFileIndex, setSelectedFileIndex] = useState(0);

  const filesLenght = files.length;
  const prevDisabled = selectedFileIndex === 0;
  const nextDisabled = selectedFileIndex === filesLenght - 1;

  const onPrev = () => {
    onSelect(files[selectedFileIndex - 1]);
  };

  const onNext = () => {
    onSelect(files[selectedFileIndex + 1]);
  };

  useEffect(() => {
    setSelectedFileIndex(files.findIndex(({ id }) => id === selectedFileId));
  }, [files, selectedFileId]);

  return (
    <Box
      sx={(t) => ({
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 16,
        position: 'sticky',
        bottom: 0,
        zIndex: 1,
        backgroundColor: t.palette.background.default,
        boxShadow: `0px -2px 12px 0px ${t.palette.shadow}`,
      })}
    >
      <Typography
        variant="16_20_400"
        sx={(t) => ({
          color: t.palette.text.secondary,
        })}
      >{`${t('DOCUMENT')} ${
        selectedFileIndex + 1
      }(${filesLenght})`}</Typography>
      <Box sx={{ display: 'flex', gap: 4 }}>
        <Button
          variant="pagination"
          onClick={onPrev}
          disabled={prevDisabled}
          startIcon={<ChevronIcon />}
          sx={{
            'span.MuiButton-startIcon': {
              transform: 'rotate(180deg)',
            },
          }}
        >
          {t('PREV')}
        </Button>
        <Button
          variant="pagination"
          onClick={onNext}
          disabled={nextDisabled}
          endIcon={<ChevronIcon />}
        >
          {t('NEXT')}
        </Button>
      </Box>
    </Box>
  );
};
