import { type FC, useState } from 'react';
import { Box } from '@mui/material';

import { Scrollbar, GeneralInfo, Media, Biomarkers } from 'components';
import { useMediaQuery } from 'hooks';
import {
  type SharedSrotageBiomarker,
  type StorageFile,
} from 'types/sharedStorage';
import { MediaModal } from 'components/Media/fragments';
import { sx } from './styles';

interface DocumentType {
  id: string;
  data: StorageFile;
  biomarkers: SharedSrotageBiomarker[];
  isMoreOneDocument?: boolean;
}

export const Document: FC<DocumentType> = ({
  id,
  data,
  biomarkers,
  isMoreOneDocument,
}) => {
  const { desktop } = useMediaQuery();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isShowBiomarkers = Boolean(biomarkers.length);

  if (desktop) {
    return (
      <Box sx={sx.desktopRoot}>
        <Box sx={sx.desktopWrapper}>
          {isMoreOneDocument ? (
            <>
              <Box sx={sx.desktopSeveralDocsInfo}>
                <GeneralInfo
                  id={id}
                  data={data}
                  onClick={() => {
                    setIsMenuOpen(true);
                  }}
                />
                {isShowBiomarkers && <Biomarkers data={biomarkers} />}
              </Box>
              {data.id && (
                <Box sx={sx.desktopSeveralDocsMedia}>
                  <Media
                    id={id}
                    fileId={data.id}
                    onClick={() => {
                      setIsMenuOpen(true);
                    }}
                  />
                </Box>
              )}
            </>
          ) : (
            <>
              <Box sx={sx.desktopGeneralInfo}>
                <GeneralInfo
                  id={id}
                  data={data}
                  onClick={() => {
                    setIsMenuOpen(true);
                  }}
                />
              </Box>
              {isShowBiomarkers && (
                <Box sx={sx.desktopBiomarkers}>
                  <Biomarkers data={biomarkers} />
                </Box>
              )}
              {data.id && (
                <Box sx={sx.desktopMedia}>
                  <Media
                    id={id}
                    fileId={data.id}
                    onClick={() => {
                      setIsMenuOpen(true);
                    }}
                  />
                </Box>
              )}
            </>
          )}
        </Box>
        {isMenuOpen && data.id && (
          <MediaModal
            id={id}
            fileId={data.id}
            onClose={() => {
              setIsMenuOpen(false);
            }}
          />
        )}
      </Box>
    );
  }

  return (
    <Box sx={sx.root}>
      <Scrollbar>
        <Box sx={sx.wrapper}>
          <Box sx={sx.flexContainer}>
            <GeneralInfo
              id={id}
              data={data}
              onClick={() => {
                setIsMenuOpen(true);
              }}
            />
          </Box>
          {isShowBiomarkers && (
            <Box sx={sx.flexContainer}>
              <Biomarkers data={biomarkers} />
            </Box>
          )}
          {data.id && (
            <Box sx={sx.media}>
              <Media
                id={id}
                fileId={data.id}
                onClick={() => {
                  setIsMenuOpen(true);
                }}
              />
            </Box>
          )}
        </Box>
      </Scrollbar>
      {isMenuOpen && data.id && (
        <MediaModal
          id={id}
          fileId={data.id}
          onClose={() => {
            setIsMenuOpen(false);
          }}
        />
      )}
    </Box>
  );
};
