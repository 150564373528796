import { type FC } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Scrollbar } from 'components';
import { useMediaQuery } from 'hooks';
import { type SharedSrotageBiomarker } from 'types/sharedStorage';

import { HeadCellDivider } from './fragments';
import { sx } from './styles';
import { Formatter } from 'utils';

interface BiomarkersType {
  data: SharedSrotageBiomarker[];
}

export const Biomarkers: FC<BiomarkersType> = ({ data }) => {
  const { mobile } = useMediaQuery();
  const { t } = useTranslation();

  return (
    <Box sx={sx.root}>
      <Scrollbar sx={sx.scrollbar}>
        <Table aria-label="biomarkers" sx={sx.table}>
          <TableHead sx={sx.tableHead}>
            <TableRow>
              <TableCell sx={sx.tableHeaderCell}>
                <Typography sx={sx.tableHeaderText}>
                  {Formatter.formatTableTitle(t('BIOMARKER'), mobile)}
                </Typography>
                {!mobile && <HeadCellDivider />}
              </TableCell>
              <TableCell sx={sx.tableHeaderCell}>
                <Typography sx={sx.tableHeaderText}>
                  {Formatter.formatTableTitle(t('RESULT'), mobile)}
                </Typography>
                {!mobile && <HeadCellDivider />}
              </TableCell>
              {!mobile && (
                <TableCell sx={sx.tableHeaderCell}>
                  <Typography variant="16_20_800">
                    {Formatter.formatTableTitle(t('UNITS'), mobile)}
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(({ biomarker, value, unit }, index) => (
              <TableRow key={index} sx={sx.tableBodyRow}>
                <TableCell>
                  <Typography sx={sx.tableBodyText}>{biomarker}</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={sx.tableBodyText}>{value}</Typography>
                </TableCell>
                {!mobile && (
                  <TableCell>
                    <Typography variant="14_18_600">{unit}</Typography>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Box>
  );
};
