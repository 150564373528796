import axios from 'axios';
import { Parse } from 'utils';

const { service } = Parse.getAppPathnames();

const services = {
  pd: window.env?.REACT_APP_BASE_API ?? process.env.REACT_APP_BASE_API,
};

const baseApi = services[service as keyof typeof services] || '';

const baseURL = `${baseApi}/api/`;

const configApi = {
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const API = axios.create(configApi);
