import { type FC } from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from 'assets/images/logo.svg';

export const Header: FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={(t) => ({
        position: 'sticky',
        top: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 28px 12px 20px',
        borderBottom: `1px solid ${t.palette.border.default}`,
        boxShadow: `0px 4px 8px 0px ${t.palette.shadow}`,
        backgroundColor: t.palette.background.default,
        zIndex: 1,
      })}
    >
      <Logo />
      <Button
        variant="secondary"
        size="small"
        href={window.env?.REACT_APP_REGISTER ?? process.env.REACT_APP_REGISTER}
      >
        {t('REGISTER')}
      </Button>
    </Box>
  );
};
