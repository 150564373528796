import { type SxProps, type Theme } from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  root: (t) => ({
    width: '30%',
    minWidth: 240,
    maxWidth: 450,
    borderRight: `1px solid ${t.palette.border.default}`,
  }),

  listWrapper: {
    height: '100%',
  },

  listItem: (t) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 3,
    padding: { sm: '12px 18px', lg: '12px 24px' },
    cursor: 'pointer',
    borderBottom: `1px solid ${t.palette.border.default}`,
    '&:hover': {
      backgroundColor: t.palette.background.paper,
    },
  }),

  nameText: {
    typography: {
      sm: '16_20_700',
      lg: '20_24_700',
    },
    wordBreak: 'break-word',
  },

  dateText: (t) => ({
    color: t.palette.text.secondary,
  }),
};
