import { createTheme } from '@mui/material';
import { initialColors } from './colors';

export const muiTheme = createTheme({
  palette: {
    text: {
      primary: initialColors.grey8,
      secondary: initialColors.grey6,
    },
    background: {
      default: initialColors.white,
      paper: initialColors.grey1,
    },
    table: {
      bg1: initialColors.primary1,
      bg2: initialColors.primary2,
    },
    white: initialColors.white,
    border: {
      default: initialColors.grey3,
      table: initialColors.grey4,
      image: initialColors.grey5,
    },
    shadow: initialColors.shadow,
    scrollbar: {
      track: initialColors.grey1,
      thumb: initialColors.grey3,
    },
    button: {
      p2: initialColors.primary2,
      p7: initialColors.primary7,
      p8: initialColors.primary8,
      g3: initialColors.grey3,
      g4: initialColors.grey4,
      g7: initialColors.grey7,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      lg: 1280,
    },
  },
  spacing: 1,
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: 'Nunito Sans',
    allVariants: {
      color: initialColors.grey8,
    },
    '10_14_600': {
      fontSize: 10,
      lineHeight: '14px',
      fontWeight: 600,
    },
    '12_16_400': {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 400,
    },
    '12_16_600': {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 600,
    },
    '12_16_700': {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 700,
    },
    '12_16_800': {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 800,
    },
    '14_18_600': {
      fontSize: 14,
      lineHeight: '18px',
      fontWeight: 600,
    },
    '14_18_700': {
      fontSize: 14,
      lineHeight: '18px',
      fontWeight: 700,
    },
    '16_20_400': {
      fontSize: 16,
      lineHeight: '20px',
      fontWeight: 400,
    },
    '16_20_700': {
      fontSize: 16,
      lineHeight: '20px',
      fontWeight: 700,
    },
    '16_20_800': {
      fontSize: 16,
      lineHeight: '20px',
      fontWeight: 800,
    },
    '18_22_600': {
      fontSize: 18,
      lineHeight: '22px',
      fontWeight: 600,
    },
    '20_24_700': {
      fontSize: 20,
      lineHeight: '24px',
      fontWeight: 700,
    },
    '24_28_800': {
      fontSize: 24,
      lineHeight: '28px',
      fontWeight: 800,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            border: `1.5px solid ${initialColors.primary8}`,
            color: initialColors.primary8,
            '&:hover': {
              borderColor: initialColors.primary7,
              color: initialColors.primary7,
            },
            '&:active': {
              backgroundColor: initialColors.primary2,
              borderColor: initialColors.primary8,
              color: initialColors.primary8,
            },
            '&:disabled': {
              backgroundColor: initialColors.white,
              color: initialColors.grey4,
              borderColor: initialColors.grey3,
            },
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            display: 'inline-flex',
            gap: 6,
            border: `1.5px solid ${initialColors.grey5}`,
            backgroundColor: initialColors.grey1,
            color: initialColors.grey7,
            '&:hover, &:active': {
              border: `1.5px solid ${initialColors.grey6}`,
              backgroundColor: initialColors.grey2,
              color: initialColors.grey8,
            },
          },
        },
        {
          props: { variant: 'pagination' },
          style: {
            padding: '6px 8px',
            border: `1px solid ${initialColors.grey4}`,
            backgroundColor: initialColors.white,
            color: initialColors.grey8,
            '&:hover, &:active': {
              backgroundColor: initialColors.grey1,
            },
            '.MuiButton-startIcon': {
              marginLeft: 0,
            },
            '.MuiButton-endIcon': {
              marginRight: 0,
            },
            '&:disabled': {
              backgroundColor: initialColors.grey1,
              color: initialColors.grey4,
              'svg path': {
                stroke: initialColors.grey4,
              },
            },
          },
        },
      ],
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: 16,
          fontWeight: 600,
          borderRadius: 2,
          textTransform: 'initial',
          boxShadow: 'initial',
          backgroundColor: theme.palette.background.default,
          '&:hover': {
            backgroundColor: theme.palette.background.default,
            boxShadow: 'initial',
          },
        }),
        sizeSmall: {
          height: 32,
          padding: '4px 12px',
        },
        sizeMedium: {
          height: 40,
          padding: '10px 20px',
        },
      },
      defaultProps: {
        variant: 'primary',
        size: 'medium',
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: 40,
          borderRadius: 2,
          border: `1.5px solid ${theme.palette.button.p7}`,
          backgroundColor: theme.palette.background.default,
          padding: 8,
          'svg path': {
            stroke: theme.palette.button.p8,
          },
          '&:hover': {
            borderColor: theme.palette.button.p7,
            backgroundColor: theme.palette.background.default,
            'svg path': {
              stroke: theme.palette.button.p7,
            },
          },
          '&:active': {
            backgroundColor: theme.palette.button.p2,
            borderColor: theme.palette.button.p8,
            'svg path': {
              stroke: theme.palette.button.p8,
            },
          },
          '&:disabled': {
            backgroundColor: theme.palette.white,
            borderColor: theme.palette.button.g3,
            'svg path': {
              stroke: theme.palette.button.g4,
            },
          },
        }),
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTable: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.up('lg')]: {
            borderCollapse: 'separate',
            borderStyle: 'solid',
            borderColor: theme.palette.border.table,
            borderWidth: '0 1px 1px 1px',
          },
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: ({ theme }) => ({
          borderBottom: 'unset',
          borderStyle: 'solid',
          borderWidth: '1px 0',
          borderColor: theme.palette.border.table,
          height: 40,
          padding: '0 12px',

          [theme.breakpoints.down('sm')]: {
            borderColor: theme.palette.border.default,
            ':first-of-type': {
              padding: '0 12px 0 16px',
            },
          },
          [theme.breakpoints.up('sm')]: {
            height: 42,
            padding: '0 12px 0 24px',
          },
        }),
        body: ({ theme }) => ({
          borderBottom: `1px solid ${theme.palette.border.default}`,
          padding: '6px 12px',
          [theme.breakpoints.down('sm')]: {
            ':first-of-type': {
              paddingLeft: 16,
            },
          },
          [theme.breakpoints.up('sm')]: {
            padding: '12px 12px 12px 24px',
          },
        }),
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => ({
          margin: '48px 0',
          maxWidth: 'unset',
          background: 'transparent',
          boxShadow: 'initial',
          overflow: 'hidden',
          height: '100%',
          [theme.breakpoints.up('lg')]: {
            maxHeight: 'calc(100vh - 96px)',
          },
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
          overflow: 'hidden',
          padding: '0 56px',
          height: '100%',
          [theme.breakpoints.up('lg')]: {
            minWidth: 550,
          },
          [theme.breakpoints.down('lg')]: {
            padding: '42px 0 0 0',
            backgroundColor: theme.palette.background.default,
          },
        }),
      },
    },
  },
});
