import { type FC, type PropsWithChildren } from 'react';
import { styled, css, Box, type SxProps, type Theme } from '@mui/material';
import { isBrowser } from 'react-device-detect';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

interface IScrollbar extends PropsWithChildren {
  sx?: SxProps<Theme>;
}

export const Scrollbar: FC<IScrollbar> = ({ children, sx }) => {
  if (isBrowser) {
    return <ScrollbarBrowser sx={sx}>{children}</ScrollbarBrowser>;
  }

  return (
    <Box sx={{ ...sx, width: '100%', overflow: 'auto', height: '100%' }}>
      {children}
    </Box>
  );
};

const ScrollbarBrowser = styled(PerfectScrollbar)(
  ({ theme: t }) => css`
    width: 100%;
    .ps__thumb-y {
      background-color: ${t.palette.scrollbar.thumb} !important;
      border-radius: 0 !important;
      width: 6px !important;
      right: 0;
    }
    .ps__rail-y {
      opacity: 1;
      background-color: ${t.palette.scrollbar.track} !important;
      width: 6px;
      z-index: 100;
    }
  `
);
