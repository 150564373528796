import { type FC, useEffect, useState } from 'react';

import { Document, DocumentList, DocumentPagination, Page } from 'components';
import {
  useMediaQuery,
  useSharedStorageList,
  useSharedStorageDetails,
} from 'hooks';
import {
  type SharedSrotageBiomarker,
  type StorageFile,
} from 'types/sharedStorage';
import { Parse } from 'utils';

export const Main: FC = () => {
  const { id } = Parse.getAppPathnames();
  const { mobile } = useMediaQuery();
  const { data, isFetching, isError } = useSharedStorageList({ id });
  const { data: details } = useSharedStorageDetails({ id });

  const [selectedFile, setSelectedFile] = useState<StorageFile | null>(null);
  const [selectedBiomarkers, setSelectedBiomarkers] = useState<
    SharedSrotageBiomarker[]
  >([]);

  const isMoreOneDocument = data && data.length > 1;

  useEffect(() => {
    if (data) {
      setSelectedFile(data[0]);
    }
  }, [data]);

  useEffect(() => {
    if (selectedFile) {
      const selectedBiomarkerList =
        details?.find(({ id }) => id === selectedFile.id)?.biomarkers || [];

      setSelectedBiomarkers(selectedBiomarkerList);
    }
  }, [details, selectedFile]);

  return (
    <Page isError={isError} isLoading={isFetching}>
      {!mobile && isMoreOneDocument && (
        <DocumentList
          files={data}
          selectedFileId={selectedFile?.id}
          onSelect={setSelectedFile}
        />
      )}
      {selectedFile && (
        <Document
          id={id}
          data={selectedFile}
          biomarkers={selectedBiomarkers}
          isMoreOneDocument={isMoreOneDocument}
        />
      )}
      {mobile && isMoreOneDocument && (
        <DocumentPagination
          files={data}
          selectedFileId={selectedFile?.id}
          onSelect={setSelectedFile}
        />
      )}
    </Page>
  );
};
