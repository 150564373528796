import { type FC, type RefObject } from 'react';

import { type SharedStorageFile } from 'types/sharedStorage';
import { Formatter } from 'utils';

type PrintComponentType = SharedStorageFile & {
  innerRef: RefObject<HTMLDivElement>;
};

export const PrintComponent: FC<PrintComponentType> = ({
  data,
  contentType: prefix,
  innerRef,
}) => {
  return (
    <div ref={innerRef}>
      <img
        src={Formatter.srcFromBase64({ base64: String(data), prefix })}
        alt=""
      />
    </div>
  );
};
