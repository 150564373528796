import { type SxProps, type Theme } from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  root: {
    padding: {
      xs: '0 16px',
      sm: '0 18px',
      lg: '24px 24px 30px 24px',
    },
    display: 'flex',
    flexDirection: 'column',
    gap: {
      xs: 6,
      sm: 12,
    },
  },

  buttonsWrapper: {
    display: 'flex',
    alignSelf: 'flex-start',
    paddingTop: {
      xs: 6,
      lg: 18,
    },
  },

  printButton: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0,
  },

  downloadButton: {
    borderTopLeftRadius: {
      lg: 0,
    },
    borderBottomLeftRadius: {
      lg: 0,
    },
  },

  printComponent: {
    display: 'none',
  },
};
