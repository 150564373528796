import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import translationEn from './locales/en/translation.json';

export enum Language {
  EN_US = 'en-US',
  RU = 'ru',
}

const resources = {
  en: {
    translation: translationEn,
  },
};

void i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: Language.EN_US,
    lng: Language.EN_US,
    debug: process.env.NODE_ENV === 'development',
    resources,
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
