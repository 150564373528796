import { type FC } from 'react';
import { Divider } from '@mui/material';

export const HeadCellDivider: FC = () => {
  return (
    <Divider
      orientation="vertical"
      sx={(t) => ({
        position: 'absolute',
        height: 24,
        top: 9,
        right: 0,
        borderColor: t.palette.border.default,
      })}
    />
  );
};
