import { type SxProps, type Theme } from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  desktopRoot: {
    width: '100%',
  },

  desktopWrapper: {
    display: 'flex',
    flexDirection: 'row',
    rowGap: 24,
    height: '100%',
    width: '100%',
  },

  desktopSeveralDocsInfo: (t) => ({
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 50%',
    borderRight: `1px solid ${t.palette.border.default}`,
    paddingBottom: 24,
  }),

  desktopSeveralDocsMedia: {
    display: 'flex',
    flex: '1 1 50%',
    justifyContent: 'center',
  },

  desktopGeneralInfo: (t) => ({
    display: 'flex',
    flex: '1 1 450px',
    borderRight: `1px solid ${t.palette.border.default}`,
  }),

  desktopBiomarkers: (t) => ({
    display: 'flex',
    flex: '1 1 790px',
    borderRight: `1px solid ${t.palette.border.default}`,
    padding: '24px 0',
  }),

  desktopMedia: {
    display: 'flex',
    justifyContent: 'center',
    flex: '1 1 1440px',
  },

  root: {
    display: 'flex',
    flex: {
      xs: 1,
      sm: 'initial',
    },
    width: '100%',
    overflow: 'hidden',
    padding: {
      xs: '16px 0',
      sm: '18px 0',
    },
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 18,
    height: '100%',
    width: '100%',
  },

  flexContainer: {
    display: 'flex',
  },

  media: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
  },
};
