import { type FC, type PropsWithChildren } from 'react';
import { Error } from 'components/Error';
import { Box } from '@mui/material';
import { Preloader } from 'components/Preloader';

interface PageType {
  isError: boolean;
  isLoading: boolean;
}

export const Page: FC<PropsWithChildren<PageType>> = ({
  isError,
  isLoading,
  children,
}) => {
  return (
    <Box
      sx={(t) => ({
        display: 'flex',
        position: { xs: 'relative', lg: 'initial' },
        flex: 1,
        padding: { xs: 0, sm: 24 },
        backgroundColor: t.palette.background.paper,
        overflow: { sm: 'hidden' },
      })}
    >
      <Box
        sx={(t) => ({
          display: 'flex',
          flex: 1,
          backgroundColor: t.palette.background.default,
          border: `1px solid ${t.palette.border.default}`,
          borderRadius: '4px',
          height: { lg: '100%' },
          flexDirection: { xs: 'column', sm: 'row' },
          overflow: { lg: 'hidden' },
        })}
      >
        {isLoading ? (
          <Preloader />
        ) : (
          <>{isError ? <Error /> : <>{children}</>}</>
        )}
      </Box>
    </Box>
  );
};
