import { styled, type SxProps, type Theme } from '@mui/material';

export const sx: Partial<Record<string, SxProps<Theme>>> = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: {
      xs: '0 16px',
      sm: '0 18px',
      lg: 24,
    },
  },
};

export const Image = styled('img')`
  max-width: 100%;
  /* Fix Safari: the image is stretched beyond its size */
  max-height: calc(100% - 4px);
  align-self: flex-start;
  cursor: pointer;
  object-fit: contain;
  border: 1px solid ${(props) => props.theme.palette.border.image};
`;
