import { type FC } from 'react';
import { Box, Typography } from '@mui/material';

import { Scrollbar } from 'components';
import { type StorageFile } from 'types/sharedStorage';
import { Formatter } from 'utils';

import { sx } from './styles';

interface DocumentListType {
  files: StorageFile[];
  selectedFileId: StorageFile['id'];
  onSelect: (file: StorageFile) => void;
}

export const DocumentList: FC<DocumentListType> = ({
  files,
  selectedFileId,
  onSelect,
}) => {
  return (
    <Box sx={sx.root}>
      <Scrollbar>
        <Box sx={sx.listWrapper}>
          {files.map((file) => (
            <Box
              key={file.id}
              onClick={() => {
                onSelect(file);
              }}
              sx={[
                ...(Array.isArray(sx.listItem) ? sx.listItem : [sx.listItem]),
                (t) => ({
                  ...(selectedFileId === file.id && {
                    backgroundColor: t.palette.background.paper,
                  }),
                }),
              ]}
            >
              <Typography sx={sx.nameText}>{file.name}</Typography>
              <Typography variant="12_16_600" sx={sx.dateText}>
                {Formatter.dateFormat({
                  date: file.date,
                  from: 'Base',
                  to: 'DateSlashed',
                })}
              </Typography>
            </Box>
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );
};
