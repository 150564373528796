import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { ReactComponent as ErrorImage } from 'assets/images/error.svg';

export const Error: FC = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        gap: 24,
        marginTop: { xs: 126, sm: 258, lg: 242 },
      }}
    >
      <ErrorImage />
      <Typography sx={{ typography: { xs: '14_18_600', sm: '18_22_600' } }}>
        {t('ERROR_SCREEN')}
      </Typography>
    </Box>
  );
};
