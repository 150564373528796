import { type AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { sharedStorageService } from 'services';
import {
  type SharedStorageThumbnailFileRequest,
  type SharedStorageThumbnailFile,
} from 'types/sharedStorage';
import { type ValidationError } from 'types/error';

export const useSharedStorageThumbnailFile = (
  params: Partial<SharedStorageThumbnailFileRequest>
) => {
  return useQuery<SharedStorageThumbnailFile, AxiosError<ValidationError>>({
    queryKey: ['sharedStorageThumbnailFile', params],
    queryFn: () => sharedStorageService.getThumbnailFile(params),
    enabled: Boolean(params.id && params.fileId),
  });
};
