import { type FC } from 'react';
import { Box, Typography } from '@mui/material';

interface ItemType {
  title: string;
  text?: string;
}

export const Item: FC<ItemType> = ({ title, text = '-' }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', columnGap: 8 }}>
      <Typography sx={{ typography: { xs: '12_16_800', sm: '16_20_800' } }}>
        {title}
      </Typography>
      <Typography sx={{ typography: { xs: '12_16_400', sm: '16_20_400' } }}>
        {text}
      </Typography>
    </Box>
  );
};
