/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AccessRequest {
  /** Account access type */
  access?: AccountAccessType;
  avatarUrl?: string;
  /** @format date */
  beginDate?: string;
  /** Short contact info for access requests */
  contact?: AccessRequestContact;
  /** @format date-time */
  createdDate?: string;
  /** @format date */
  endDate?: string;
  /** @format uuid */
  id?: string;
}

/** Short contact info for access requests */
export interface AccessRequestContact {
  firstName?: string;
  lastName?: string;
  middleName?: string;
}

export interface AccessRequests {
  incoming?: AccessRequest[];
  outgoing?: AccessRequest[];
}

/** Access type */
export enum AccessType {
  ACCOUNT = 'ACCOUNT',
  COMPANY = 'COMPANY',
}

export interface AccessesResponse {
  companyAccesses?: CompanyAccess[];
  incomingAccountAccesses?: AccountAccess[];
  outgoingAccountAccesses?: AccountAccess[];
}

export interface AccountAccess {
  /** Account access type */
  access?: AccountAccessType;
  avatarUrl?: string;
  /** @format date */
  beginDate?: string;
  contact?: Contact;
  /** @format date */
  endDate?: string;
  /** @format uuid */
  id?: string;
}

/** Account access type */
export enum AccountAccessType {
  CREATOR_CONTACT = 'CREATOR_CONTACT',
  CREATOR_ACCOUNT = 'CREATOR_ACCOUNT',
  CONTACT_REPRESENT = 'CONTACT_REPRESENT',
  CONTACT_PROXY = 'CONTACT_PROXY',
}

export interface AccountDetails {
  avatarUrl?: string;
  /** @format date */
  birthDate?: string;
  birthDateS?: string;
  birthSex?: BirthSex;
  email?: string;
  ethnicity?: EthnicityType;
  firstName?: string;
  gender?: GenderType;
  /** @format uuid */
  guarantorId?: string;
  /** @format uuid */
  id?: string;
  lastName?: string;
  middleName?: string;
  phone?: string;
  race?: RaceType;
  sexualOrientation?: SexualOrientationType;
  ssnTail?: string;
}

export interface AccountInsurance {
  carrier?: string;
  /** @format date */
  endDate?: string;
  /** @format uuid */
  id?: string;
  name?: string;
  number?: string;
  owner?: boolean;
  primary?: boolean;
}

export interface AccountInsuranceDetails {
  guarantor?: Guarantor;
  /** Account insurances */
  insurances?: AccountInsurance[];
}

/** Account status */
export enum AccountStatus {
  NEW = 'NEW',
  ACTIVE = 'ACTIVE',
  INVITE = 'INVITE',
  BLOCKED = 'BLOCKED',
}

export interface Address {
  /** Additional address */
  additional?: string;
  /** City */
  city?: string;
  /**
   * Contact address id
   * @format uuid
   */
  id?: string;
  /** Main address */
  main?: string;
  /** Postal Code (Zip Code) */
  postalCode?: string;
  /** States of America */
  state?: StateType;
}

export interface AddressChangeRequest {
  address1: string;
  address2?: string;
  city: string;
  postalCode: string;
  /** States of America */
  state: StateType;
}

export interface AnonymousPatient {
  /** @format date */
  birthDate?: string;
  birthSex?: BirthSex;
  firstName?: string;
  /** @format uuid */
  id?: string;
  lastName?: string;
  middleName?: string;
}

export interface Appointment {
  anonymousPatient?: AnonymousPatient;
  /** @format uuid */
  id?: string;
  patient?: AccountDetails;
  professional?: ProfessionalShortInfo;
  /** @format date-time */
  startDate?: string;
  status?: AppointmentStatus;
  /** Appointment types */
  type?: AppointmentType;
}

/** Saved files */
export interface AppointmentFile {
  /** @format uuid */
  id?: string;
  name?: string;
}

export interface AppointmentFileData {
  contentType?: string;
  data?: string;
}

export interface AppointmentFull {
  address?: CompanyAddress;
  anonymousPatient?: AnonymousPatient;
  comment?: string;
  email?: string;
  /** @format date-time */
  endDate?: string;
  files?: AppointmentFile[];
  /** @format double */
  height?: number;
  /** @format uuid */
  id?: string;
  insurancePlan?: InsurancePlan;
  patient?: AccountDetails;
  phone?: string;
  professional?: ProfessionalInfo;
  /** @format date-time */
  startDate?: string;
  status?: AppointmentStatus;
  /** Appointment types */
  type?: AppointmentType;
  /** @format double */
  weight?: number;
}

export enum AppointmentStatus {
  CREATED = 'CREATED',
  WAITINGCONFIRMATION = 'WAITINGCONFIRMATION',
  CONFIRMED = 'CONFIRMED',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
}

/** Appointment types */
export enum AppointmentType {
  IN_PERSON = 'IN_PERSON',
  VIDEO = 'VIDEO',
  HOME_VISIT = 'HOME_VISIT',
}

/** Object with error message «Internal Server Error» */
export interface BaseError {
  /** Error message */
  message?: string;
}

/** Base phone */
export type BasePhone = BaseBasePhone &
  (
    | BaseBasePhoneTypeMapping<'HOME_PHONE', HomePhone>
    | BaseBasePhoneTypeMapping<'MOBILE_PHONE', MobilePhone>
    | BaseBasePhoneTypeMapping<'WORK_PHONE', WorkPhone>
  );

/** Base relation error */
// @ts-ignore
export type BaseRelationError = BaseBaseRelationError &
  (
    | BaseBaseRelationErrorTypeMapping<
        'ACCOUNT_STATUS_ERROR',
        BaseRelationError
      >
    | BaseBaseRelationErrorTypeMapping<'BIRTH_DATE_ERROR', BaseRelationError>
    | BaseBaseRelationErrorTypeMapping<'BIRTH_SEX_ERROR', BaseRelationError>
    | BaseBaseRelationErrorTypeMapping<
        'GREATER_EIGHTEEN_ERROR',
        BaseRelationError
      >
    | BaseBaseRelationErrorTypeMapping<'LESS_EIGHTEEN_ERROR', BaseRelationError>
    | BaseBaseRelationErrorTypeMapping<'PARENT_AGE_ERROR', BaseRelationError>
    | BaseBaseRelationErrorTypeMapping<
        'RELATION_CONTRADICT_ERROR',
        BaseRelationError
      >
    | BaseBaseRelationErrorTypeMapping<
        'RELATION_DUPLICATE_ERROR',
        RelationDuplicateError
      >
    | BaseBaseRelationErrorTypeMapping<
        'RELATION_REQUEST_DUPLICATE_ERROR',
        RelationRequestDuplicateError
      >
  );

/** Base Sent Relation Request Result */
export type BaseSentRelationRequestResult = BaseBaseSentRelationRequestResult &
  (
    | BaseBaseSentRelationRequestResultTypeMapping<
        'EMAIL',
        EmailSentRelationRequestResult
      >
    | BaseBaseSentRelationRequestResultTypeMapping<
        'PHONE',
        PhoneSentRelationRequestResult
      >
    | BaseBaseSentRelationRequestResultTypeMapping<
        'UNSENT',
        UnsentRelationRequestResult
      >
  );

export enum BirthSex {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  UNKNOWN = 'UNKNOWN',
}

export interface Company {
  description?: string;
  /** @format uuid */
  id?: string;
  logoUrl?: string;
  name?: string;
}

export interface CompanyAccess {
  /** Company access type */
  access?: CompanyAccessType;
  /** @format date */
  beginDate?: string;
  company?: Company;
  /** @format date */
  endDate?: string;
  /** @format uuid */
  id?: string;
}

/** Company access type */
export enum CompanyAccessType {
  CREATOR_CONTACT = 'CREATOR_CONTACT',
  DOCUMENT_CREATOR = 'DOCUMENT_CREATOR',
  CONTACT_MEDCARE = 'CONTACT_MEDCARE',
  ACCOUNT_EMPLOYER = 'ACCOUNT_EMPLOYER',
}

export interface CompanyAddress {
  address1?: string;
  address2?: string;
  city?: string;
  /** @format date */
  endDate?: string;
  faxNumber?: string;
  googlePlaceId?: string;
  /** @format uuid */
  id?: string;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  postalCode?: string;
  /** States of America */
  state?: StateType;
  telephoneNumber?: string;
}

export interface Contact {
  /** @format date */
  birthDate?: string;
  birthDateS?: string;
  birthSex?: BirthSex;
  ethnicity?: EthnicityType;
  firstName?: string;
  gender?: GenderType;
  /** @format uuid */
  guarantorId?: string;
  /** @format uuid */
  id?: string;
  lastName?: string;
  middleName?: string;
  race?: RaceType;
  sexualOrientation?: SexualOrientationType;
  ssnTail?: string;
}

/** period */
export interface DatePeriod {
  /** @format date */
  from?: string;
  /** @format date */
  to?: string;
}

export interface DeleteAccessRequest {
  /** Access type */
  type: AccessType;
}

export interface Doctor {
  avatarUrl?: string;
  fullName?: string;
  /** @format int64 */
  id?: number;
  shortName?: string;
  speciality?: string;
  /** @format double */
  totalRating?: number;
}

export enum DoubleOptInStatus {
  NOT_SENT = 'NOT_SENT',
  SENT = 'SENT',
  CONFIRMED = 'CONFIRMED',
}

export interface DynamicsResult {
  comment?: string;
  criticalReference?: Reference;
  /** @format date-time */
  dateTime?: string;
  qualityReference?: QualityReference;
  reference?: Reference;
  units?: string;
  value?: string;
}

export interface DynamicsTest {
  code?: string;
  loinc?: string;
  name?: string;
  results?: DynamicsResult[];
}

export interface Email {
  allowResultsDelivery?: boolean;
  doubleOptIn?: DoubleOptInStatus;
  email?: string;
  /** @format uuid */
  id?: string;
  main?: boolean;
  ownershipConfirmed?: boolean;
}

export type EmailSentRelationRequestResult =
  BaseBaseSentRelationRequestResult & {
    email?: string;
  };

export enum EthnicityType {
  HISPANIC_OR_LATINO = 'HISPANIC_OR_LATINO',
  NOT_HISPANIC_OR_LATINO = 'NOT_HISPANIC_OR_LATINO',
  OTH = 'OTH',
  UNK = 'UNK',
  ASKU = 'ASKU',
}

export interface FieldError {
  error?: string;
  field?: string;
}

export enum FlagType {
  NORMAL = 'NORMAL',
  ABNORMAL = 'ABNORMAL',
  CRITICAL = 'CRITICAL',
}

export enum GenderType {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  FEMALE_TO_MALE = 'FEMALE_TO_MALE',
  MALE_TO_FEMALE = 'MALE_TO_FEMALE',
  GENDERQUEER = 'GENDERQUEER',
  OTH = 'OTH',
  ASKU = 'ASKU',
}

export interface Guarantor {
  additional?: string;
  /** @format date */
  birthDate?: string;
  birthSex?: BirthSex;
  city?: string;
  firstName?: string;
  /** @format uuid */
  id?: string;
  lastName?: string;
  main?: string;
  middleName?: string;
  phone?: string;
  postalCode?: string;
  /** Relation type */
  relationType?: RelationType;
  ssnTail?: string;
  /** States of America */
  state?: StateType;
}

/** Guarantor create request */
export interface GuarantorCreateRequest {
  /** SSN tail */
  address1?: NillableString;
  /** SSN tail */
  address2?: NillableString;
  /** @format date */
  birthDate: string;
  birthSex: BirthSex;
  /** SSN tail */
  city?: NillableString;
  firstName: string;
  lastName: string;
  /** SSN tail */
  middleName?: NillableString;
  phone?: NillablePhoneWrapper;
  /** SSN tail */
  postalCode?: NillableString;
  /** Relation type */
  relationType: RelationType;
  /** SSN tail */
  ssnTail?: NillableString;
  state?: NillableStateType;
}

/** Guarantor edit request */
export interface GuarantorEditRequest {
  /** SSN tail */
  address1?: NillableString;
  /** SSN tail */
  address2?: NillableString;
  /** Birth Date */
  birthDate?: NillableLocalDate;
  /** Birth sex */
  birthSex?: NillableBirthSex;
  /** SSN tail */
  city?: NillableString;
  /** SSN tail */
  firstName?: NillableString;
  /** SSN tail */
  lastName?: NillableString;
  /** SSN tail */
  middleName?: NillableString;
  phone?: NillablePhoneWrapper;
  /** SSN tail */
  postalCode?: NillableString;
  relationType?: NillableRelationType;
  /** SSN tail */
  ssnTail?: NillableString;
  state?: NillableStateType;
}

export interface HealthPolicyChangeRequest {
  /**
   * Guarantor id to delete
   * @format uuid
   */
  deletedGuarantorId?: string;
  /** Insurance ids to delete */
  deletedInsuranceIds?: string[];
  /** Guarantor create request */
  guarantorCreate?: GuarantorCreateRequest;
  /** Guarantor edit request */
  guarantorEdit?: GuarantorEditRequest;
  /** Insurances to create */
  insurancesCreate?: InsuranceCreateRequest[];
  /** Insurances to edit */
  insurancesEdit?: InsuranceEditRequest[];
}

export type HomePhone = BaseBasePhone;

export interface InsuranceCarrier {
  /** @format uuid */
  id?: string;
  name?: string;
}

/** Insurances to create */
export interface InsuranceCreateRequest {
  /** Birth Date */
  endDate?: NillableLocalDate;
  /** @format uuid */
  insurancePlanId: string;
  number: string;
  owner: boolean;
  primary: boolean;
}

/** Insurances to edit */
export interface InsuranceEditRequest {
  /** @format date */
  endDate: string;
  /** @format uuid */
  id: string;
}

export interface InsurancePlan {
  /** @format uuid */
  id?: string;
  name?: string;
}

export type MobilePhone = BaseBasePhone & {
  allowResultsDelivery?: boolean;
  main?: boolean;
  ownershipConfirmed?: boolean;
};

/** Birth sex */
export interface NillableBirthSex {
  value?: BirthSex;
}

/** Ethnicity */
export interface NillableEthnicityType {
  value?: EthnicityType;
}

/** Gender */
export interface NillableGenderType {
  value?: GenderType;
}

/** Birth Date */
export interface NillableLocalDate {
  /** @format date */
  value?: string;
}

export interface NillablePhoneWrapper {
  value?: string;
}

/** Race */
export interface NillableRaceType {
  value?: RaceType;
}

export interface NillableRelationType {
  /** Relation type */
  value?: RelationType;
}

/** SexualOrientation */
export interface NillableSexualOrientationType {
  value?: SexualOrientationType;
}

export interface NillableStateType {
  /** States of America */
  value?: StateType;
}

/** SSN tail */
export interface NillableString {
  value?: string;
}

export interface NotificationsConnection {
  applicationId?: string;
  /** @format int32 */
  channels?: number;
  /** @format uuid */
  connectionId?: string;
  /** @format int32 */
  expired?: number;
  host?: string;
  password?: string;
  queue?: string;
  user?: string;
  virtualHost?: string;
  wssUrl?: string;
}

export interface OrderDetails {
  /** @format date */
  collected?: string;
  /** @format int64 */
  number?: number;
  provider?: OrderingProvider;
  /** @format date */
  reported?: string;
}

export interface OrderingProvider {
  firstName?: string;
  /** @format uuid */
  id?: string;
  lastName?: string;
  middleName?: string;
}

export interface PageRelationContactSearchResult {
  hasNext?: boolean;
  items?: RelationContactSearchResult[];
}

export interface Panel {
  code?: string;
  /** @format uuid */
  id?: string;
  name?: string;
  products?: Product[];
}

export interface Patient {
  /** @format date */
  birthDate?: string;
  birthSex?: BirthSex;
  firstName?: string;
  lastName?: string;
  middleName?: string;
}

export type PhoneSentRelationRequestResult =
  BaseBaseSentRelationRequestResult & {
    phone?: string;
  };

/** Phone type */
export enum PhoneType {
  HOME_PHONE = 'HOME_PHONE',
  WORK_PHONE = 'WORK_PHONE',
  MOBILE_PHONE = 'MOBILE_PHONE',
}

export interface Product {
  code?: string;
  /** @format uuid */
  id?: string;
  name?: string;
  tests?: Test[];
}

export interface ProfessionalInfo {
  account?: AccountDetails;
  speciality?: Speciality;
  /** @format double */
  totalRating?: number;
  typePrefix?: string;
  typeSuffix?: string;
}

export interface ProfessionalShortInfo {
  account?: AccountDetails;
  speciality?: Speciality;
  typePrefix?: string;
  typeSuffix?: string;
}

export interface ProfessionalSlots {
  /** @format date */
  date?: string;
  slots?: Slot[];
}

export interface ProfessionalSlotsRequest {
  /**
   * Professional address id
   * @format uuid
   */
  addressId: string;
  /** period */
  period: DatePeriod;
  /**
   * Appointment types
   * @uniqueItems true
   */
  types: AppointmentType[];
}

export interface Profile {
  actualAddresses?: Address;
  avatarUrl?: string;
  contact?: Contact;
  emails?: Email[];
  insurances?: AccountInsurance[];
  /** Phones */
  phones?: BasePhone[];
  relations?: Relation[];
}

export interface QualityReference {
  isOutOfRef?: boolean;
  value?: string;
}

export enum RaceType {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
  ASIAN = 'ASIAN',
  BLACK_OR_AFRICAN_AMERICAN = 'BLACK_OR_AFRICAN_AMERICAN',
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER = 'NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER',
  WHITE = 'WHITE',
  OTHER = 'OTHER',
  ASKU = 'ASKU',
  UNKNOWN = 'UNKNOWN',
}

export interface Reference {
  isOutOfRef?: boolean;
  /** @format double */
  maxValue?: number;
  /** @format double */
  minValue?: number;
}

export interface Relation {
  avatarUrl?: string;
  /** Short contact info for relations */
  contact?: RelationContact;
  /** @format uuid */
  id?: string;
  /** Relation type */
  type?: RelationType;
}

export interface RelationAccountAccess {
  /** Account access type */
  access?: AccountAccessType;
  /** @format date */
  beginDate?: string;
  /** @format date */
  endDate?: string;
  /** @format uuid */
  id?: string;
}

/** Short contact info for relations */
export interface RelationContact {
  birthDate?: string;
  firstName?: string;
  /** @format uuid */
  id?: string;
  lastName?: string;
  middleName?: string;
}

export interface RelationContactBatchCreateRequest {
  /**
   * Birth Date
   * @format date
   */
  birthDate: string;
  birthSex: BirthSex;
  /** Email */
  email?: string;
  ethnicity?: EthnicityType;
  /**
   * First name
   * @max 50
   */
  firstName: string;
  gender: GenderType;
  /** Create new insurance */
  insurance?: RelationContactInsuranceCreateRequest;
  /**
   * Use account insurance
   * @format uuid
   */
  insuranceId?: string;
  /**
   * Last name
   * @max 50
   */
  lastName: string;
  /**
   * Middle name
   * @max 50
   */
  middleName?: string;
  /** Phone */
  phone?: string;
  race?: RaceType;
  recaptcha?: string;
  /** Request relation type */
  relationType: RequestRelationType;
  sexualOrientation?: SexualOrientationType;
}

export interface RelationContactEditRequest {
  /** Birth Date */
  birthDate?: NillableLocalDate;
  /** Birth sex */
  birthSex?: NillableBirthSex;
  /** Ethnicity */
  ethnicity?: NillableEthnicityType;
  /** SSN tail */
  firstName?: NillableString;
  /** Gender */
  gender?: NillableGenderType;
  /** SSN tail */
  lastName?: NillableString;
  /** SSN tail */
  middleName?: NillableString;
  /** Race */
  race?: NillableRaceType;
  /** SexualOrientation */
  sexualOrientation?: NillableSexualOrientationType;
  /** SSN tail */
  ssnTail?: NillableString;
}

/** Create new insurance */
export interface RelationContactInsuranceCreateRequest {
  /** Birth Date */
  endDate?: NillableLocalDate;
  /** @format uuid */
  insurancePlanId: string;
  number: string;
  owner: boolean;
}

/** Relation contact search request */
export interface RelationContactSearch {
  /** Search filter */
  filter?: string;
  /** @format int32 */
  perPage: number;
  /** Policy number */
  policyNumber?: string;
  recaptcha?: string;
  /** Request relation type */
  relationType: RequestRelationType;
  /**
   * @format int32
   * @default 0
   */
  startPage?: number;
}

export interface RelationContactSearchResult {
  accountAvatarUrl?: string;
  /** Account status */
  accountStatus?: AccountStatus;
  /** @format date */
  birthDate?: string;
  birthDateS?: string;
  email?: string;
  firstName?: string;
  /** @format uuid */
  id?: string;
  lastName?: string;
  middleName?: string;
  phone?: string;
  policyNumber?: string;
  /** Base relation error */
  relationError?: BaseRelationError;
  ssnTail?: string;
}

export interface RelationContactSimilarError {
  results?: RelationContactSearchResult[];
  type?: RelationContactSimilarErrorType;
}

export enum RelationContactSimilarErrorType {
  CONTACT_SIMILAR_STRATEGY = 'CONTACT_SIMILAR_STRATEGY',
  UNIQUE_UNCONFIRMED_PHONE = 'UNIQUE_UNCONFIRMED_PHONE',
  UNIQUE_CONFIRMED_PHONE = 'UNIQUE_CONFIRMED_PHONE',
}

export type RelationDuplicateError = BaseBaseRelationError & {
  /** @format uuid */
  relationId?: string;
};

/** Relation error type */
export enum RelationErrorType {
  PARENT_AGE_ERROR = 'PARENT_AGE_ERROR',
  LESS_EIGHTEEN_ERROR = 'LESS_EIGHTEEN_ERROR',
  GREATER_EIGHTEEN_ERROR = 'GREATER_EIGHTEEN_ERROR',
  BIRTH_DATE_ERROR = 'BIRTH_DATE_ERROR',
  BIRTH_SEX_ERROR = 'BIRTH_SEX_ERROR',
  ACCOUNT_STATUS_ERROR = 'ACCOUNT_STATUS_ERROR',
  RELATION_CONTRADICT_ERROR = 'RELATION_CONTRADICT_ERROR',
  RELATION_DUPLICATE_ERROR = 'RELATION_DUPLICATE_ERROR',
  RELATION_REQUEST_DUPLICATE_ERROR = 'RELATION_REQUEST_DUPLICATE_ERROR',
}

export interface RelationProfile {
  accesses?: RelationAccountAccess[];
  actualAddresses?: Address;
  avatarUrl?: string;
  contact?: Contact;
  emails?: Email[];
  guarantor?: Guarantor;
  insurances?: AccountInsurance[];
  /** Phones */
  phones?: BasePhone[];
  relations?: Relation[];
}

export interface RelationRequest {
  avatarUrl?: string;
  /** Short contact info for relation requests */
  contact?: RelationRequestContact;
  /** @format date-time */
  createdDate?: string;
  /** @format uuid */
  id?: string;
  /** Relation type */
  type?: RelationType;
}

/** Short contact info for relation requests */
export interface RelationRequestContact {
  firstName?: string;
  lastName?: string;
  middleName?: string;
}

export interface RelationRequestContactEditRequest {
  /** Birth Date */
  birthDate?: NillableLocalDate;
  /** Birth sex */
  birthSex?: NillableBirthSex;
}

export interface RelationRequestCreateRequest {
  /**
   * Requested Contact Identifier
   * @format uuid
   */
  contactId: string;
  recaptcha?: string;
  /** Request relation type */
  relationType: RequestRelationType;
}

export type RelationRequestDuplicateError = BaseBaseRelationError & {
  /** @format date-time */
  createdDate?: string;
};

/** Relation Request Send Type */
export enum RelationRequestSendType {
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  UNSENT = 'UNSENT',
}

export interface RelationRequests {
  incoming?: RelationRequest[];
  outgoing?: RelationRequest[];
}

/** Relation type */
export enum RelationType {
  CHD = 'CHD',
  DEP = 'DEP',
  EMC = 'EMC',
  FCH = 'FCH',
  FTH = 'FTH',
  GCH = 'GCH',
  GRP = 'GRP',
  MTH = 'MTH',
  OTH = 'OTH',
  WRD = 'WRD',
  SPO = 'SPO',
  HUSB = 'HUSB',
  WIFE = 'WIFE',
}

/** Request relation type */
export enum RequestRelationType {
  CHD = 'CHD',
  DEP = 'DEP',
  FCH = 'FCH',
  FTH = 'FTH',
  GCH = 'GCH',
  GRP = 'GRP',
  MTH = 'MTH',
  SPO = 'SPO',
  HUSB = 'HUSB',
  WIFE = 'WIFE',
}

export interface RescheduleAppointmentRequest {
  /**
   * Address id
   * @format uuid
   */
  addressId: string;
  /** Appointment types */
  appointmentType: AppointmentType;
  /**
   * End date
   * @format date-time
   */
  endDate: string;
  /**
   * Start date
   * @format date-time
   */
  startDate: string;
}

export interface Result {
  /** @format date */
  collected?: string;
  /** @format uuid */
  companyId?: string;
  flag?: FlagType;
  id?: string;
  physician?: OrderingProvider;
  /** @format date */
  reported?: string;
}

export interface ResultDetail {
  id?: string;
  orderDetails?: OrderDetails;
  panels?: Panel[];
  patient?: Patient;
  products?: Product[];
}

export interface SendRequest {
  /** @format uuid */
  companyId: string;
  email: string;
}

export enum SexualOrientationType {
  LESBIAN_GAY_HOMOSEXUAL = 'LESBIAN_GAY_HOMOSEXUAL',
  STRAIGHT_HETEROSEXUAL = 'STRAIGHT_HETEROSEXUAL',
  BISEXUAL = 'BISEXUAL',
  OTH = 'OTH',
  UNK = 'UNK',
  ASKU = 'ASKU',
}

export interface SharedStorage {
  files?: StorageFile[];
  /** @format uuid */
  id?: string;
}

export interface SharedStorageDetails {
  /** @format uuid */
  id?: string;
  storageDetails?: StorageDetails[];
}

export interface SharedStorageRequest {
  password?: string;
}

export interface Slot {
  /**
   * from
   * @format partial-time
   */
  from?: string;
  /**
   * to
   * @format partial-time
   */
  to?: string;
  /** Type */
  types?: AppointmentType[];
}

export interface Speciality {
  /** @format uuid */
  id?: string;
  isPrimary?: boolean;
  name?: string;
}

/** States of America */
export enum StateType {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AS = 'AS',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  FM = 'FM',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MH = 'MH',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  PW = 'PW',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY',
  ZZ = 'ZZ',
}

export interface StorageBiomarker {
  biomarker?: string;
  /** @format uuid */
  id?: string;
  unit?: string;
  value?: string;
}

export interface StorageDetails {
  biomarkers?: StorageBiomarker[];
  category?: StorageFileCategory;
  /** @format date */
  date?: string;
  description?: string;
  favorite?: boolean;
  /** @format uuid */
  id?: string;
  name?: string;
  /** @format int64 */
  size?: number;
  type?: StorageFileType;
}

export interface StorageFile {
  category?: StorageFileCategory;
  /** @format date */
  date?: string;
  description?: string;
  favorite?: boolean;
  /** @format uuid */
  id?: string;
  name?: string;
  /** @format int64 */
  size?: number;
  type?: StorageFileType;
}

export enum StorageFileCategory {
  LABORATORY_REPORT = 'LABORATORY_REPORT',
  MEDICAL_RECORD = 'MEDICAL_RECORD',
  IMAGING_REPORT = 'IMAGING_REPORT',
  MEDICAL_IMAGE = 'MEDICAL_IMAGE',
  PRESCRIPTION = 'PRESCRIPTION',
  MISCELLANEOUS = 'MISCELLANEOUS',
}

export interface StorageFileData {
  contentType?: string;
  data?: string;
}

export enum StorageFileType {
  JPG = 'JPG',
  PNG = 'PNG',
  PDF = 'PDF',
}

export interface StorageThumbnail {
  data?: StorageFileData;
  type?: StorageThumbnailType;
}

export enum StorageThumbnailType {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export interface Test {
  code?: string;
  comment?: string;
  criticalReference?: Reference;
  /** @format uuid */
  id?: string;
  loinc?: string;
  name?: string;
  qualityReference?: QualityReference;
  reference?: Reference;
  units?: string;
  value?: string;
}

export interface UnreadMessageCount {
  /** @format int64 */
  count?: number;
}

export type UnsentRelationRequestResult = BaseBaseSentRelationRequestResult;

export interface UpdateAppointmentRequest {
  /** Files to delete */
  deletedFileIds?: string[];
  description?: string;
  /** Saved files */
  files?: AppointmentFile[];
  /**
   * Patient height
   * @format double
   */
  height?: number;
  /**
   * Patient weight
   * @format double
   */
  weight?: number;
}

/** Object with error message «Bad request» */
export interface ValidationError {
  /** OWNER_ROLE_NOT_FOUND, ROLE_NOT_FOUND, ROLE_COMPANY_MODULE_EXISTS, COMPANY_MODULE_NOT_FOUND, COMPANY_MODULE_ALREADY_EXISTS, ACCOUNT_COMPANY_MODULE_ROLE_EXISTS, ACCOUNT_COMPANY_INVITE_NOT_FOUND, ACCOUNT_COMPANY_INVITE_EXPIRED, ACCOUNT_COMPANY_INVITE_EXISTS, PERMISSIONS_NOT_FOUND, CURRENT_PASSWORD_INCORRECT, NEW_PASSWORD_EQUALS_CURRENT_PASSWORD, MOBILE_PHONE_REQUIRED, REMOVE_MAIN_PHONE, EMAIL_EXISTS, EMAIL_NOT_CONFIRMED, EMAIL_CONFIRMATION_TOKEN_ERROR, REMOVE_MAIN_EMAIL, NPI_NOT_ACTIVE, PARENT_NPI_REQUIRED, REQUEST_ORIGIN_NOT_FOUND, UNPROCESSABLE_URL, GUARANTOR_NOT_EXISTS, HEALTH_POLICY_NOT_EXISTS, GUARANTOR_EXISTS, CONTACT_NOT_EXISTS, ACCOUNT_NOT_EXISTS, NOT_NULL, NOT_BLANK, NOT_EMPTY, ONLY_UPPER_LETTERS, EMAIL_VALIDATION_ERROR, INVALID_FORMAT_ERROR, PHONE_VALIDATION_ERROR, MAX_SIZE_1000, MAX_SIZE_500, MAX_SIZE_255, MAX_SIZE_128, MAX_SIZE_55, MAX_SIZE_50, MAX_SIZE_40, MAX_SIZE_4, MIN_0, MIN_1, MAX_2, MAX_30, MAX_8_2, MAX_1323, CHANGE_FORBIDDEN, ZIP_CODE_NUMBER_VALIDATION_ERROR, FUTURE_DATE, MAX_UPLOAD_SIZE_EXCEEDED, FILE_TYPE_UNSUPPORTED, DATE_PERIOD_VALIDATION_ERROR, RECAPTCHA_VALIDATION_ERROR, BIRTHDATE_VALIDATION_ERROR, STORAGE_FILE_NOT_FOUND, SHARED_STORAGE_NOT_FOUND, STORAGE_BIOMARKER_NOT_FOUND, BIOMARKER_BIOMARKER_UNIT_NOT_FOUND, MODIFICATION_FORBIDDEN, MIME_TYPE_NOT_SUPPORTED, IDENTITY_COMMON_VALIDATION_ERROR, CATALOG_COMMON_VALIDATION_ERROR, PROFESSIONAL_SLOT_BUSY, PROFESSIONAL_SLOT_UNAVAILABLE, BOOKING_UNKNOWN_VALIDATION_ERROR, CONTACT_COMMON_VALIDATION_ERROR, DYNAMIC_COMMON_VALIDATION_ERROR, PASSPORT_COMMON_VALIDATION_ERROR, REPORT_COMMON_VALIDATION_ERROR */
  error?: string;
  /** Array of field errors */
  fieldErrors?: {
    /** OWNER_ROLE_NOT_FOUND, ROLE_NOT_FOUND, ROLE_COMPANY_MODULE_EXISTS, COMPANY_MODULE_NOT_FOUND, COMPANY_MODULE_ALREADY_EXISTS, ACCOUNT_COMPANY_MODULE_ROLE_EXISTS, ACCOUNT_COMPANY_INVITE_NOT_FOUND, ACCOUNT_COMPANY_INVITE_EXPIRED, ACCOUNT_COMPANY_INVITE_EXISTS, PERMISSIONS_NOT_FOUND, CURRENT_PASSWORD_INCORRECT, NEW_PASSWORD_EQUALS_CURRENT_PASSWORD, MOBILE_PHONE_REQUIRED, REMOVE_MAIN_PHONE, EMAIL_EXISTS, EMAIL_NOT_CONFIRMED, EMAIL_CONFIRMATION_TOKEN_ERROR, REMOVE_MAIN_EMAIL, NPI_NOT_ACTIVE, PARENT_NPI_REQUIRED, REQUEST_ORIGIN_NOT_FOUND, UNPROCESSABLE_URL, GUARANTOR_NOT_EXISTS, HEALTH_POLICY_NOT_EXISTS, GUARANTOR_EXISTS, CONTACT_NOT_EXISTS, ACCOUNT_NOT_EXISTS, NOT_NULL, NOT_BLANK, NOT_EMPTY, ONLY_UPPER_LETTERS, EMAIL_VALIDATION_ERROR, INVALID_FORMAT_ERROR, PHONE_VALIDATION_ERROR, MAX_SIZE_1000, MAX_SIZE_500, MAX_SIZE_255, MAX_SIZE_128, MAX_SIZE_55, MAX_SIZE_50, MAX_SIZE_40, MAX_SIZE_4, MIN_0, MIN_1, MAX_2, MAX_30, MAX_8_2, MAX_1323, CHANGE_FORBIDDEN, ZIP_CODE_NUMBER_VALIDATION_ERROR, FUTURE_DATE, MAX_UPLOAD_SIZE_EXCEEDED, FILE_TYPE_UNSUPPORTED, DATE_PERIOD_VALIDATION_ERROR, RECAPTCHA_VALIDATION_ERROR, BIRTHDATE_VALIDATION_ERROR, STORAGE_FILE_NOT_FOUND, SHARED_STORAGE_NOT_FOUND, STORAGE_BIOMARKER_NOT_FOUND, BIOMARKER_BIOMARKER_UNIT_NOT_FOUND, MODIFICATION_FORBIDDEN, MIME_TYPE_NOT_SUPPORTED, IDENTITY_COMMON_VALIDATION_ERROR, CATALOG_COMMON_VALIDATION_ERROR, PROFESSIONAL_SLOT_BUSY, PROFESSIONAL_SLOT_UNAVAILABLE, BOOKING_UNKNOWN_VALIDATION_ERROR, CONTACT_COMMON_VALIDATION_ERROR, DYNAMIC_COMMON_VALIDATION_ERROR, PASSPORT_COMMON_VALIDATION_ERROR, REPORT_COMMON_VALIDATION_ERROR */
    error?: string;
    /** Field name */
    field?: string;
  }[];
}

export type WorkPhone = BaseBasePhone;

/** Base phone */
interface BaseBasePhone {
  /** @format uuid */
  id?: string;
  phone?: string;
  /** Phone type */
  type?: PhoneType;
}

type BaseBasePhoneTypeMapping<Key, Type> = {
  type: Key;
} & Type;

/** Base relation error */
interface BaseBaseRelationError {
  /** Relation error type */
  type?: RelationErrorType;
}

type BaseBaseRelationErrorTypeMapping<Key, Type> = {
  type: Key;
} & Type;

/** Base Sent Relation Request Result */
interface BaseBaseSentRelationRequestResult {
  /** @format date-time */
  createdDate?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  /** Relation Request Send Type */
  type?: RelationRequestSendType;
}

type BaseBaseSentRelationRequestResultTypeMapping<Key, Type> = {
  type: Key;
} & Type;

export interface UpdateAppointmentPayload {
  data: UpdateAppointmentRequest;
  files?: File[];
}

export interface GetInsuranceCarriersParams {
  filter?: string;
}

export interface GetInsurancePlansByInsuranceCarrierParams {
  /** @format uuid */
  insuranceCarrierId: string;
}

export interface CreateRelationContactParams {
  /** @default false */
  force?: boolean;
}

export interface DownloadResultParams {
  /** @format uuid */
  companyId: string;
  /** Result id */
  id: string;
}

export interface GetSharedStorageFileThumbnailsParams {
  /** @uniqueItems true */
  type: StorageThumbnailType[];
  /** @format uuid */
  id: string;
  /** @format uuid */
  fileId: string;
}
