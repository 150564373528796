import { type FC } from 'react';
import { Box } from '@mui/material';

import { Header, Main } from 'components';
import { useTranslation } from 'react-i18next';
import { useInitVivicaLoader } from 'hooks';

export const App: FC = () => {
  const { ready } = useTranslation();
  useInitVivicaLoader(ready);

  return (
    <Box
      sx={(t) => ({
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: '100vh',
        overflow: { lg: 'hidden' },
        backgroundColor: t.palette.background.default,
      })}
    >
      <Header />
      <Main />
    </Box>
  );
};
