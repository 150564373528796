import { type SharedStorageFile } from 'types/sharedStorage';

export function isPdf(
  media: SharedStorageFile
): media is Required<SharedStorageFile> {
  return (
    media &&
    media.contentType === 'application/pdf' &&
    typeof media.data === 'string'
  );
}

export function isImage(
  media: SharedStorageFile
): media is Required<SharedStorageFile> {
  return (
    media &&
    typeof media.contentType === 'string' &&
    media.contentType !== 'application/pdf' &&
    typeof media.data === 'string'
  );
}
