import { type FC, type PropsWithChildren } from 'react';
import { GlobalStyles, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { muiTheme } from './theme';
import { globalStyles } from './globalStyles';

export const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <GlobalStyles styles={globalStyles} />
      {children}
    </MuiThemeProvider>
  );
};
