import { Box } from '@mui/material';
import { useMediaQuery } from 'hooks';
import { type FC } from 'react';
import { PulseLoader } from 'react-spinners';
import { initialColors } from 'theme/colors';

export const Preloader: FC = () => {
  const size = useMediaQuery();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <PulseLoader
        size={getSize(size)}
        margin={size.desktop ? 3 : 2}
        color={initialColors.primary8}
      />
    </Box>
  );
};

function getSize({
  mobile,
  tablet,
  desktop,
}: ReturnType<typeof useMediaQuery>) {
  return mobile ? 12 : tablet ? 18 : desktop ? 24 : 18;
}
