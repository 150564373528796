import { type AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { sharedStorageService } from 'services';
import {
  type SharedStorageListRequest,
  type StorageFile,
} from 'types/sharedStorage';
import { type ValidationError } from 'types/error';

export const useSharedStorageList = (params: SharedStorageListRequest) => {
  return useQuery<StorageFile[], AxiosError<ValidationError>>({
    queryKey: ['sharedStorageList', params],
    queryFn: () => sharedStorageService.getList(params),
  });
};
