const paletteColors = {
  grey: {
    100: '#f4f7f9',
    200: '#ecf1f5',
    300: '#dee4eb',
    400: '#c2cad4',
    500: '#a9b5c2',
    600: '#788493',
    700: '#4c5b6d',
    800: '#35404e',
  },
  primary: {
    100: '#fff4f7',
    200: '#fbe9ee',
    700: '#e46386',
    800: '#dc3763',
    900: '#a81d42',
  },
};

export const initialColors = {
  transparent: 'transparent',
  white: '#ffffff',
  shadow: 'rgba(0, 0, 0, 0.10)',
  primary1: paletteColors.primary[100],
  primary2: paletteColors.primary[200],
  primary8: paletteColors.primary[800],
  primary7: paletteColors.primary[700],
  primary9: paletteColors.primary[900],
  grey1: paletteColors.grey[100],
  grey2: paletteColors.grey[200],
  grey3: paletteColors.grey[300],
  grey4: paletteColors.grey[400],
  grey5: paletteColors.grey[500],
  grey6: paletteColors.grey[600],
  grey7: paletteColors.grey[700],
  grey8: paletteColors.grey[800],
} as const;
