import { type Interpolation } from '@emotion/react';
import { type Theme } from '@mui/material';

export const globalStyles: Interpolation<Theme> = () => {
  return {
    body: {
      fontFamily: 'Nunito Sans, sans-serif',
      padding: 0,
      margin: 0,
    },
  };
};
